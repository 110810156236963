import React from 'react'
import '../style.scss'
import Highlighter from "react-highlight-words";
//assets
import cityIcon from "../../../assets/icons/city-icon.svg"

const SearchSuggestion = ({list, active, setActive, searchedText}) => {


    return (
        <div className={'search-suggestion'}>
            <div className={'d-flex mb-3'}>
                <img className={'icon-20 mr-2'} src={cityIcon} alt={'alt'}/>
                <p className={'ubuntu-500w-18s-28h mb-0'}>City</p>
            </div>
            <div className={'mb-4'}>
                {list && list.map((item,) => {
                    return <div key={item.CMCityid}
                                className={'d-flex cursor-pointer ' + (`${active === item.CMCityid ? 'active' : ''}`)}
                                onClick={() => setActive(item.CMCityid, item.CMCityname)}>

                        <p className={'open-400w-16s-24h opacity-60 mb-0 capitalize_text'}>

                            <Highlighter
                                // activeClassName={styles.Active}
                                activeIndex={-1}
                                caseSensitive={false}
                                // highlightClassName={styles.Highlight}
                                highlightStyle={{fontWeight: '800'}}
                                // sanitize={latinize}
                                searchWords={[searchedText]}
                                textToHighlight={item.CMCityname?.toLowerCase()}
                            />
                        </p>
                    </div>
                })
                }
            </div>
        </div>

    )
}

export default SearchSuggestion



export const api = process.env.REACT_APP_BACKEND_API,
cloudFlareSiteKey = process.env.REACT_APP_CAPTCHA_SITEKEY_DEV
export const isMapEnabled = process.env.REACT_APP_MAP_ENABLED

export const googleClientId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
export const isInsuranceEnabled = process.env.REACT_APP_INSURANCE_ENABLED === "true"
export const isFnbEnabled = process.env.REACT_APP_FNB_ENABLED === "true"




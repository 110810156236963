import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link , useLocation } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import RecommendedBlogs from "../BlogLandingPage/Components/RecommendedBlogs/RecommendedBlogs";
import ExtraBlogPart from "../BlogLandingPage/Components/ExtraBlogPart";
import axios from "axios";
import remarkGfm from "remark-gfm";

// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

const failureIllustrationIcon = process.env.REACT_APP_CDN_LINK + `assets/images/failure-illustration.png`

function SpecificBlog() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const [blogData , setBlogData] = useState({})
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const fetchData = async () => {
        setLoading(true)
        try {
          const response = await axios.get(`${process.env.REACT_APP_CMS_PROD_API}api/homepage-blogs?populate=*&filters[route][$eqi]=${location.pathname}`);
          if(response.data){
            console.log("specific_data",response.data);
            setBlogData(response.data)
            setLoading(false)
          }else {
            setLoading(false)
            setBlogData({})

          }
        } catch (error) {
          setLoading(false)
          setBlogData({})
          console.log('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, [location]);


    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>{blogData?.attributes?.metaTitle}</title>
                <link rel="canonical" href={blogData?.attributes?.canonicalUrl} hreflang="en-IN" />
                <meta name="description" content={blogData?.attributes?.metaDescription}/>
                <meta name="keywords" content={blogData?.attributes?.keywords}></meta>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                  { loading? <Loader/>:  <MainContent isDesktopOrLaptop={isDesktopOrLaptop} loading={loading} dataContent={blogData} />}
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        { loading?<Loader/>:  <MainContent isDesktopOrLaptop={isDesktopOrLaptop} loading={loading} dataContent={blogData} />}
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = ({loading,dataContent}) => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    




    
    console.log({dataContent})
    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        { dataContent && dataContent?.attributes?.breadcrumb1 && <script type="application/ld+json">
                                {dataContent?.attributes?.breadcrumb1}
                            </script>}
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>{dataContent && dataContent?.attributes?.route.split('/')[2]}</p>
                    </div>
                    }
                    <div className="containers d-flex flex-column">
                        <div className="main-blog-extra-blog">
                            {  Object.entries(dataContent).length  ? <div className="main-blog-card">
                                            <h1>{dataContent.attributes?.title}</h1>
                                            <br />
                                            <img src={dataContent.attributes?.img} alt={dataContent.attributes?.imgAlt} />
                                            <br />
                                            <div className="blog-content">
                                                <ReactMarkdown  children={dataContent.attributes?.content} remarkPlugins={[remarkGfm]} />
                                            </div>
                                        </div>
                            :
                            <div className="main-blog-card">
                                                        <div
                        className={
                            "d-flex flex-column align-items-center text-center "
                        }
                    >
                        <img
                            src={failureIllustrationIcon}
                            alt={"alt"}
                            className={"main-illustration"}
                            style={{width:'218px', height: '218px'}}
                        />
                        <p className={"ubuntu-700w-20s-32h mb-3"}>
                        We’re sorry!
                        </p>
                        <p className={"open-500w-18s-28h w-75"}>
                        The  blog looking for, is currently unavailable.  <br /> However, we have some other blogs that you might enjoy exploring.
                        </p>
                    </div>
                            </div>
                            }
                            <ExtraBlogPart  dataContentTitle={ dataContent && dataContent?.attributes?.title} />
                        </div>
                        <RecommendedBlogs dataContentCategory={ Object.entries(dataContent).length ? dataContent?.attributes?.tags[0] : 'Adventure'} />
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default SpecificBlog
import React, {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import { useNavigate} from "react-router";
import { useSearchParams , Link } from "react-router-dom"
import Loader from "../../components/Loader/Loader";
import {CustomToast} from "../../components/CustomToast/CustomToast"
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import "./Sitemap.scss"
import RouteHeaderComponent from "../../components/RouteHeadersComponent/RouteHeaderComponent";

function Sitemap() {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

  return (
    <div className={"sitemap"}>
                <Helmet>
                    <title>NueGo Sitemap</title>
                    <link rel="canonical" href="https://www.nuego.in/sitemap" hreflang="en-IN" />
                    <meta name="description" content="Explore NueGo's bus booking travel services with our comprehensive sitemap. Easily navigate through routes, booking options and travel information to plan your perfect journey with NueGo."/>
                    <meta name="keywords" content="NueGo sitemap,NueGo all routes,magazine,blog,popular searches,new routes"></meta>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
            {

            isDesktopOrLaptop?<AuthModalLayout>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </AuthModalLayout>
            :
            <MobileSubScreenLayout back={() => navigate("/")} title={"NueGo Sitemap"}>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </MobileSubScreenLayout>
            }
        </div>
  )
}

export const MainContent = ({isDesktopOrLaptop}) => {

    const [loading, setLoading] = useState(false);
   

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-600w-16s-24h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color mt-2",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    const NuegoTravel= [
    {
      question: "Travel With NueGo",
      seo : '',
    answer : [{
      
      'route': 'Home',
      'routeLink': "/"
    }, 
    {
      
      'route': 'All Routes',
      'routeLink': "/all-routes"
    }, 
    {
      "route": "Partner With Us",
      'routeLink': "/partner-with-us"
    },
    {
      
      'route': 'Offers',
      'routeLink': "/bus-booking-offers"
    }, 
    {
      "route": "Lounge",
      'routeLink': "/travel-lounge"
    },]
    },
    {
      question: 'More on NueGo',
      seo: '',
      answer: [
        {
    
    'route': 'About Us',
    'routeLink': "/about-us"
  }, 
  {
    
    'route': 'Careers',
    'routeLink': "/careers"
  }, 
  {
    "route": "Newsroom",
    'routeLink': "/news"
  },
  {
    
    'route': 'Environment',
    'routeLink': "/environment"
  }, 
  {
    "route": "Magazine",
    'routeLink': "/travel-itineraries"
  
  },
  {
    "route": "Blog",
    'routeLink': "/blog"
  },
  
      
  ]
    },
    {
      question : 'Help Center',
      seo: '',
      answer : [

        {
        
          'route': 'Support',
          'routeLink': "/call"
        }, 
        {
          
          'route': 'FAQs',
          'routeLink': "/faq"
        }, 
       
      
      ]
    } ,
    {
            
      question:"Popular Routes",
      seo: '',
      answer: [
          {route: 'Agra to Delhi Bus', routeLink: '/all-routes/bus/Agra-to-Delhi'},
          {route: 'Agra to Fatehpur Sikri Bus', routeLink: '/all-routes/bus/Agra-to-Fatehpur Sikri'},
          {route: 'Agra to Gurugram Bus', routeLink: '/all-routes/bus/Agra-to-Gurugram'},
          {route: 'Agra to Jaipur Bus', routeLink: '/all-routes/bus/Agra-to-Jaipur'},
          {route: 'Agra to Mathura Bus', routeLink: '/all-routes/bus/Agra-to-Mathura'},
          {route: 'Agra to Noida Bus', routeLink: '/all-routes/bus/Agra-to-Noida'},
          {route: 'Bhopal to Indore Bus', routeLink: '/all-routes/bus/Bhopal-to-Indore'},
          {route: 'Bhopal to Ujjain Bus', routeLink: '/all-routes/bus/Bhopal-to-Ujjain'},
          {route: 'Bangalore to Salem Bus', routeLink: '/all-routes/bus/Bangalore-to-Salem'},
          {route: 'Bangalore to Chennai Bus', routeLink: '/all-routes/bus/Bangalore-to-Chennai'},
          {route: 'Bangalore to Coimbatore Bus', routeLink: '/all-routes/bus/Bangalore-to-Coimbatore'},
          {route: 'Bangalore to Tirupati Bus', routeLink: '/all-routes/bus/Bangalore-to-Tirupati'},
          {route: 'Bangalore to Pondicherry Bus', routeLink: '/all-routes/bus/Bangalore-to-Pondicherry'},
          {route: 'Bangalore to Trichy Bus', routeLink: '/all-routes/bus/Bangalore-to-Trichy'},
          {route: 'Chandigarh to Delhi Bus', routeLink: '/all-routes/bus/Chandigarh-to-Delhi'},
          {route: 'Chennai to Pondicherry Bus', routeLink: '/all-routes/bus/Chennai-to-Pondicherry'},
          {route: 'Chennai to Tirupati Bus', routeLink: '/all-routes/bus/Chennai-to-Tirupati'},
          {route: 'Chennai to Trichy Bus', routeLink: '/all-routes/bus/Chennai-to-Trichy'},
          {route: 'Dehradun to Delhi Bus', routeLink: '/all-routes/bus/Dehradun-to-Delhi'},
          {route: 'Dehradun to Haridwar Bus', routeLink: '/all-routes/bus/Dehradun-to-Haridwar'},
          {route: 'Dehradun to Noida Bus', routeLink: '/all-routes/bus/Dehradun-to-Noida'},
          {route: 'Dehradun to Rishikesh Bus', routeLink: '/all-routes/bus/Dehradun-to-Rishikesh'},
          {route: 'Delhi to Chandigarh Bus', routeLink: '/all-routes/bus/Delhi-to-Chandigarh'},
          {route: 'Delhi to Dehradun Bus', routeLink: '/all-routes/bus/Delhi-to-Dehradun'},
          {route: 'Delhi to Gurugram Bus', routeLink: '/all-routes/bus/Delhi-to-Gurugram'},
          {route: 'Delhi to Haridwar Bus', routeLink: '/all-routes/bus/Delhi-to-Haridwar'},
          {route: 'Delhi to Jaipur Bus', routeLink: '/all-routes/bus/Delhi-to-Jaipur'},
          {route: 'Haridwar to Rishikesh Bus', routeLink: '/all-routes/bus/Haridwar-to-Rishikesh'},
          {route: 'Hyderabad to Vijayawada Bus', routeLink: '/all-routes/bus/Hyderabad-to-Vijayawada'},
          {route: 'Hyderabad to Guntur Bus', routeLink: '/all-routes/bus/Hyderabad-to-Guntur'},
          {route: 'Eluru to Hyderabad Bus', routeLink: '/all-routes/bus/Eluru-to-Hyderabad'},
          {route: 'Guntur to Visakhapatnam Bus', routeLink: '/all-routes/bus/Guntur-to-Visakhapatnam'},
          {route: 'Jaipur to Delhi Bus', routeLink: '/all-routes/bus/Jaipur-to-Delhi'},
          {route: 'Jaipur to Fatehpur Sikri Bus', routeLink: '/all-routes/bus/Jaipur-to-Fatehpur-Sikri'},
          {route: 'Shimla to Delhi Bus', routeLink: '/all-routes/bus/Shimla-to-Delhi'},
          {route: 'Ludhiana to Delhi Bus', routeLink: '/all-routes/bus/Ludhiana-to-Delhi'},
          {route: 'Rajahmundry to Visakhapatnam Bus', routeLink: '/all-routes/bus/Rajahmundry-to-Visakhapatnam'},
          {route: 'Rishikesh to Delhi Bus', routeLink: '/all-routes/bus/Rishikesh-to-Delhi'},
          {route: 'Chennai to Tirupati Bus', routeLink: '/all-routes/bus/Chennai-to-Tirupati'},
          {route: 'Tirupati to Bangalore Bus', routeLink: '/all-routes/bus/Tirupati-to-Bangalore'},
          {route: 'Tirupati to Chennai Bus', routeLink: '/all-routes/bus/Tirupati-to-Chennai'},
          {route: 'Trichy to Pondicherry Bus', routeLink: '/all-routes/bus/Trichy-to-Pondicherry'},
          {route: 'Vijayawada to Hyderabad Bus', routeLink: '/all-routes/bus/Vijayawada-to-Hyderabad'},
          {route: 'Vijayawada to Visakhapatnam Bus', routeLink: '/all-routes/bus/Vijayawada-to-Visakhapatnam'}
        ]
        
  },
  {
    question : 'New Routes',
    seo : '',
    answer : [

      {
      
        'route': 'Kota',
        'routeLink': "/all-routes?city=Kota"
      }, 
      {
        
        'route': 'Bareilly',
        'routeLink': "/all-routes?city=Bareilly"
      }, 
      {
        
        'route': 'Madurai',
        'routeLink': "/all-routes?city=Madurai"
      },
      {
        
        'route': 'Trichy',
        'routeLink': "/all-routes?city=Trichy"
      },
      {
        
        'route': 'Visakhapatnam',
        'routeLink': "/all-routes?city=Visakhapatnam"
      },
      {
        
        'route': 'Pondicherry',
        'routeLink': "/all-routes?city=Pondicherry"
      },
      {
        
        'route': 'Dehradun',
        'routeLink': "/all-routes?city=Dehradun"
      },
      {
        
        'route': 'Ujjain',
        'routeLink': "/all-routes?city=Ujjain"
      },
      {
        
        'route': 'Chandigarh',
        'routeLink': "/all-routes?city=Chandigarh"
      },
      {
        
        'route': 'Tirupati',
        'routeLink': "/all-routes?city=Tirupati"
      },
      {
        
        'route': 'Hyderabad',
        'routeLink': "/all-routes?city=Hyderabad"
        
      },
      {
        
        'route': 'Jaipur',
        'routeLink': "/all-routes?city=Jaipur"
      },
     
    
    ]
  }, 
   
 
    ]

    const blogs = [
      {
        question : 'NueGo Blogs',
        seo : "",
  
        answer: [{
          route: "10 Famous Waterfalls in India that You Cannot Miss in 2025",
          routeLink: "/blog/famous-waterfall-in-india"
        }, {
          route: "10 Best Places to Visit in India During August for Monsoon Adventure in 2025",
          routeLink: "/blog/best-august-travel-destinations-in-india-monsoon"
        }, {
          route: "7 Best Places to Visit Near Indore in 2025",
          routeLink: "/blog/places-to-visit-near-indore"
        }, {
          route: "Best Places to Visit for One-Day Trips from Bangalore",
          routeLink: "/blog/one-day-trip-from-bangalore"
        }, {
          route: "20 Travel Destinations in North India",
          routeLink: "/blog/travel-destinations-to-explore-in-north-india"
        }, {
          route: "Don't Skip Out on These Incredible South Indian Tourist Places to Visit",
          routeLink: "/blog/best-south-indian-tourist-places-to-visit"
        }, {
          route: "10 Things To Do in Pondicherry in 2025 That You Won't Find in Guidebooks",
          routeLink: "/blog/things-to-do-in-pondicherry"
        }, {
          route: "20 Places To Visit in India During Monsoon",
          routeLink: "/blog/monsoon-tourist-places-in-india"
        }, {
          route: "30 Best Things to Do in Gurugram | Latest List",
          routeLink: "/blog/things-to-do-in-gurugram"
        }, {
          route: "10 Places to Visit in India in August To Enjoy Rainy Weather 2025",
          routeLink: "/blog/best-places-to-visit-in-august"
        }, {
          route: "Complete Guide To Celebrate Holi In Mathura-Vrindavan",
          routeLink: "/blog/guide-to-celebrate-holi-in-mathura-vrindavan"
        }, {
          route: "15 Best Sightseeing Places to Visit in Haridwar",
          routeLink: "/blog/sightseeing-places-to-visit-in-haridwar"
        }, {
          route: "10 Famous Tourist Places and Attractions in Agra You Cannot Miss",
          routeLink: "/blog/famous-tourist-places-to-visit-in-agra"
        }, {
          route: "Your Complete Guide to Places to Visit in Ujjain in 2025",
          routeLink: "/blog/places-to-visit-in-ujjain"
        }, {
          route: "25 Best Places To Visit This Summer Vacation In India",
          routeLink: "/blog/places-to-visit-in-summer"
        }, {
          route: "March Long Weekend 2025: 7 Places You Must Visit",
          routeLink: "/blog/march-long-weekend-trip-ideas"
        }, {
          route: "Top 20 Solo Travel Destinations For Women | NueGo",
          routeLink: "/blog/solo-travel-destinations-for-women"
        }, {
          route: "Friendship Day Getaways: 30 Best Places to Visit with Your Friends",
          routeLink: "/blog/travel-places-to-visit-with-friends"
        }, {
          route: "10 Famous Temples in Chennai You Just Cannot Miss",
          routeLink: "/blog/famous-temples-to-visit-in-chennai-you-must-visit"
        }, {
          route: "20 Best Tourist Attractions and Activities in Noida You Must Try",
          routeLink: "/blog/top-tourist-attractions-and-activities-in-noida"
        }, {
          route: "Best Places to Eat in Jaipur—Recommended by Locals!",
          routeLink: "/blog/best-food-places-in-jaipur"
        }, {
          route: "Famous Food in Amritsar: Journey Through the City’s Authentic Punjabi Flavors",
          routeLink: "/blog/famous-food-in-amritsar"
        }, {
          route: "Delhi Famous Food: Exploring the Iconic Street Food of the Capital",
          routeLink: "/blog/famous-food-in-delhi"
        }, {
          route: "Bangalore Famous Food: Uncover the City’s Most Delicious Hidden Gems",
          routeLink: "/blog/bangalore-famous-food"
        }, {
          route: "Best Month to Visit Munnar: Winter Guide for 2024",
          routeLink: "/blog/best-month-to-visit-munnar"
        }, {
          route: "Salem Tourist Places: Must-Visit Spots for a Memorable Journey",
          routeLink: "/blog/salem-tourist-places"
        }, {
          route: "Chennai Famous Food Exploring Idlis Dosas and Chettinad Flavors",
          routeLink: "/blog/chennai-famous-food"
        }, {
          route: "Long Weekends in 2025: Turn Your Leaves into Endless  Vacations!",
          routeLink: "/blog/long-weekend-in-2025"
        }, {
          route: "Weekend Getaways from Bangalore: Short Getaway Places Near Bangalore in 2025",
          routeLink: "/blog/weekend-getaways-from-bangalore"
        }, {
          route: "Best Time to Visit Shimla: A Comprehensive Guide",
          routeLink: "/blog/best-time-to-visit-shimla"
        }, {
          route: "15 Famous Places to Visit in Vijayawada in 2025 for Sightseeing and Tourist Spots",
          routeLink: "/blog/famous-places-to-visit-in-vijayawada"
        }, {
          route: "Snow at Kashmir: A Complete Guide to the Best Snowfall Experiences in India's Paradise",
          routeLink: "/blog/snow-at-kashmir"
        }]
  
      } ,
    ]

    return (
        <>
            {isDesktopOrLaptop && <Navbar/>}
            {loading?<Loader/> :
            <div className={"main-content"}>
            {isDesktopOrLaptop && <div className=" container bread-crumb d-flex">
                      <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                      <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                       <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Sitemap</p>
                        </div>
            }
                <divc className="sitemap-container">

              
            < RouteHeaderComponent parentIndex='212' faqArray={NuegoTravel}  css="col-lg-4 col-md-6 col-sm-6 col-xs-12"/>

            < RouteHeaderComponent parentIndex='215' faqArray={blogs} css="col-lg-6 col-md-6 col-sm-6 col-xs-12" />

                </divc>

            </div>
            }
            {/* <Footer/> */}
        </>

    );
};







export default Sitemap
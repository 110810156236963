/**
 * @generated SignedSource<<40e29a90481f45fc2969a59c49d2f058>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "getTryAnotherBookingReasons",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetTryAnotherBookingReasonsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetTryAnotherBookingReasonsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4d95f2169bbf76c6918fbba6475385bc",
    "id": null,
    "metadata": {},
    "name": "GetTryAnotherBookingReasonsQuery",
    "operationKind": "query",
    "text": "query GetTryAnotherBookingReasonsQuery {\n  getTryAnotherBookingReasons\n}\n"
  }
};
})();

node.hash = "3490e6739e7979c0675d1c475d1f514f";

module.exports = node;

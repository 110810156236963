import React,{lazy, useEffect, useRef, useState , useCallback, useMemo} from 'react'
import AirDatepicker from "air-datepicker";
import {store} from "../../../../index";
import {addDaysToDate, convertDateWithMonth, getCityName, getPointsInInt, getValues,convertDateToDayDateMonth,convertDateToDayDate} from "../../../../utils/utils";
import {getAvailableBusFilterList, getDestinationList, getSourceList,} from "../../../../services/api/DataFetching";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../../components/CustomToast/CustomToast";
import {setRecentSearchAction, setRescheduleAction} from "../../../../store/Actions/CommonAction";
import SearchSuggestion from ".././SearchSuggestion";
import {rootReducer} from "../../../../store/Reducer";
import {filterDataAction} from "../../../../store/Actions/FilterAction";
import {filter_template} from "../../../Bookings/constants";
import { setAuthType } from "../../../../store/Actions/AuthActions";
import flip from "../../../../assets/revamp/flip2.png";
import CalenderWhite from "../../../../assets/icons/Calendar-white.svg"
import "react-calendar/dist/Calendar.css";
import { setAvailableBusList } from '../../../../store/Actions/BookingAction';

const localeEn = lazy(() => import("air-datepicker/locale/en"));
const Calendar = lazy(() => import("react-calendar"));



const calendar = process.env.REACT_APP_CDN_LINK + `assets/images/Calendar.png`
const seat_count = process.env.REACT_APP_CDN_LINK + `assets/images/ph_users-duotone.png`
const min = process.env.REACT_APP_CDN_LINK + `assets/images/min.png`
const plus = process.env.REACT_APP_CDN_LINK + `assets/images/Plus.png`
const search = process.env.REACT_APP_CDN_LINK + `assets/images/search.png`
const map_pin = process.env.REACT_APP_CDN_LINK + `assets/images/map-pin.png`
const circle = process.env.REACT_APP_CDN_LINK + `assets/images/circle .png`


function InnerSearchbar({
    navigate,
    setSourceAndDestinationToStore,
    selectedSearchFromRecent,
    sourcePointListFromStore,
    destinationPointListFromStore,
    userDataFromStore,
    filterDataFromStore,
    filterFromStore,
    heading,
}) {


    const [calenderbol, setcalenderbol] = useState("");
    const [flattenedHoliday , setFlattenedHoliday] = useState({});
    const [pasangercount, setpasangercount] = useState(1);
    const [startLocation, setstartLocation] = useState(
        sourcePointListFromStore?.length > 0 &&
        localStorage.getItem("currentLocation")
            ? localStorage.getItem("currentLocation")
            : ""
    );
    const [endLocation, setendLocation] = useState("");
    const [activeStartLocation, setActiveStartLocation] = useState(null);
    const [activeEndLocation, setActiveEndLocation] = useState(null);
    const sourceInputSuggestiondivRef = useRef(null)
    const destinationInputSuggestiondivRef = useRef(null)
    const sourceInputRef = useRef(null)
    const destinationInputRef = useRef(null);
    const [inputSuggestionList , setInputSuggestionList] = useState([]);
    const [destinationSuggestionList, setDestinationSuggestionList] = useState([]);
    const [calendarValue, setCalendarValue] = useState(new Date());
    const [viewCalendar, setViewCalendar] = useState(false);
    const [filterObject, setFilterObject] = useState(
        Object.keys(filterFromStore).length > 0 ? filterFromStore : filter_template
    );
    const [sourceInputSuggestion, setSourceInputSuggestion] = useState(false);
    const [destinationInputSuggestion, setDestinationInputSuggestion] =
        useState(false);

    let localStorageToken = localStorage.getItem("userToken");
    let isToken;

    const maxDate = useMemo(() => addDaysToDate(new Date(), 30), []);
    const tileClassName = useMemo(() => {
        return ({ date }) => {
          const dateString = date.toLocaleDateString("en-CA");
          
          const selectedDateIsHoliday = calendarValue && (dateString === calendarValue.toLocaleDateString("en-CA"));
          return (flattenedHoliday[dateString] && selectedDateIsHoliday)
            ? "selected-holiday"
            : flattenedHoliday[dateString]
            ? "holiday-highlight"
            : null;
        };
      }, [calendarValue, flattenedHoliday]);
      
      const handleDateChange = useCallback((value) => {
        setCalendarValue(value);
      }, [setCalendarValue]);
      
      const handleClickDay = useCallback((e) => {
        setTimeout(() => {
          setViewCalendar(false);
        }, 500);
      }, []);


      
      
    if (store) {
        isToken =
            (localStorageToken !== undefined && localStorageToken?.length > 0) ||
            (store &&
                Object.keys(store.getState().AuthReducer?.tokenData??{})?.length > 0);
    }

    const [holidaysByMonth, setHolidaysByMonth] = useState({});
    
    const [currentMonth, setCurrentMonth] = useState((new Date()).toLocaleString("default", { month: "long" }).toLowerCase() || "january")
    const handleViewChange = ({ activeStartDate }) => {
      setCurrentMonth(activeStartDate.toLocaleString("default", { month: "long" }).toLowerCase()); 
    };


  

    useEffect(() => {
        new AirDatepicker("#input", {
            locale: localeEn,
            selectedDates: [new Date()],
            // isMobile: calenderbol,
            autoClose: true,
            dateFormat: "E d MMMM",
        });
    }, []);
    let userName, userEmail, userId, userNmber , moeId;

    useEffect(() => {
        // setstartLocation(
        //     selectedSearchFromRecent.FromCityName
        //         ? selectedSearchFromRecent.FromCityName
        //         : ""
        // );
        // setendLocation(
        //     selectedSearchFromRecent.ToCityName
        //         ? selectedSearchFromRecent.ToCityName
        //         : ""
        // );
        // setActiveStartLocation(
        //     selectedSearchFromRecent.FromCityId
        //         ? selectedSearchFromRecent.FromCityId
        //         : null
        // );
        // setActiveEndLocation(
        //     selectedSearchFromRecent.ToCityId ? selectedSearchFromRecent.ToCityId : ""
        // );
        setInputSuggestionList(sourcePointListFromStore);
        
        return () => {
        };
    }, [selectedSearchFromRecent]);
    useEffect(()=>{
        setDestinationSuggestionList(destinationPointListFromStore)
    },[destinationPointListFromStore]);

    userName = localStorage.getItem("userName");
    userEmail = localStorage.getItem("userEmail");
    userId = localStorage.getItem("userID");
    userNmber = localStorage.getItem("userNumber");
    moeId = localStorage.getItem("moeID");

    useEffect(() => {
        window.Moengage.track_event("bus_home", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: "website",
            page_viewed: "yes",
            fullname: `${userName === null ? "" : userName}`,
            mobile_number: `${userNmber === null ? "" : userNmber}`,
            email: `${userEmail === null ? "" : userEmail}`,
            isloggedin: `${userNmber === null ? "no" : "yes"}`,
            origin: "web_header",
        });
       

    }, []);

    useEffect(() => {
        return () => {
        };
    }, []);

    useEffect(() => {
        // getCityName();
        store.dispatch(setRescheduleAction(false)); // to avoid reschedule flow
        let sourceListId =
            sourcePointListFromStore?.length > 0
                ? sourcePointListFromStore[0].CMCityid
                : null;
        let variable = {
            searchTerm: "",
            cityId:
                parseInt(!activeStartLocation
                    ? sourceListId
                    : activeStartLocation),
            // searchTerm: localStorage.getItem('currentLocation') !== null ? localStorage.getItem('currentLocation') : ""
        };
        if(sourceListId !== null  && activeStartLocation !== null) getDestinationList(variable);
    }, [activeStartLocation]);

    const ref = useRef();
    // constfilterDataFromStore = store.getState().FilterReducer.filter;
    const recentSearchDataFromStore = store.getState().CommonReducer.recentSearch;

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (viewCalendar && ref.current && !ref.current.contains(e.target)) {
                setViewCalendar(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [viewCalendar]);


    useEffect(() => {
        const checkIfClickedOutsideSourceInput = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (sourceInputSuggestion && sourceInputSuggestiondivRef.current && !sourceInputSuggestiondivRef.current.contains(e.target)) {
                setSourceInputSuggestion(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutsideSourceInput);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutsideSourceInput);
        };
    }, [sourceInputSuggestion]);


    useEffect(() => {
        const checkIfClickedOutsideDestinationInput = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (destinationInputSuggestion && destinationInputSuggestiondivRef.current && !destinationInputSuggestiondivRef.current.contains(e.target)) {
                setDestinationInputSuggestion(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutsideDestinationInput);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutsideDestinationInput);
        };
    }, [destinationInputSuggestion]);

    useEffect(() => {
        return () => {
        };
    }, [sourceInputSuggestion, destinationInputSuggestion]);

    new AirDatepicker("#input", {
        locale: localeEn,
        autoClose: true,
        dateFormat: "E d MMMM",
        onSelect({date}) {
            let todays = new Date();
            const tomorrow = new Date(todays);
            tomorrow.setDate(tomorrow.getDate() + 1);
            let d = String(tomorrow.getDate()).padStart(2, "0");
            let m = String(tomorrow.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyy = tomorrow.getFullYear();

            todays = m + "/" + d + "/" + yyy;

            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear();

            today = mm + "/" + dd + "/" + yyyy;
            let datee = date;
            let ddd = String(datee.getDate()).padStart(2, "0");
            let mmm = String(datee.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyyy = datee.getFullYear();
            datee = mmm + "/" + ddd + "/" + yyyyy;

            setcalenderbol(convertDateWithMonth(date));
        },
    });


    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/calendars?year=2025`)
        .then((res) => res.json())
        .then((data) => {
          setHolidaysByMonth(data)
          setFlattenedHoliday(flattenHolidaysObj(data))
          })
        .catch((e)=>console.log(e.err))
    }, []);



      function flattenHolidaysObj(holidays) {
        let flattened = {};
        
        Object.values(holidays).forEach(month => {
            Object.assign(flattened, month);
        });
        
        return flattened;
    }


    if (recentSearchDataFromStore === undefined) {
        localStorage.removeItem("persist:root");
        localStorage.clear();
        return rootReducer(undefined, "LOGOUT");
    }

    const currentDate = convertDateToDayDate(Date.now());

    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDate = convertDateToDayDate(tomorrow);

    // Get the day after tomorrow's date
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    const dayAfterTomorrowDate = convertDateToDayDate(dayAfterTomorrow);

    const onChnageStartLocationFunction = (e) => {
        let valid;
        
        setDestinationInputSuggestion(false)
        setSourceInputSuggestion(true)
        setActiveStartLocation()



        try {
            if (e.target.value !== "") {
                valid = e.target.value.match(/^[A-Za-z ]+$/);
            } else {
                valid = "";
                setActiveEndLocation(null)
                setendLocation("")
            }
        } catch (err) {
            valid = null;
            
        }

        if (valid !== null) {
           
            e.preventDefault();
            // setStartLocationTyping(true)
            setstartLocation(e.target.value);
            let variable = {
                searchTerm: e.target.value?.trim(),
            };
            
            let result =  sourcePointListFromStore.filter(item=>((`${item.CMCityname}`).toLowerCase()).includes((e.target.value).toLowerCase()));
            if (result.length) setInputSuggestionList(result);
            else {
                setstartLocation('')
                setInputSuggestionList(sourcePointListFromStore)
            }
            
            
            inputSuggestionList.forEach(option => {
              if (option.CMCityname.trim().toLowerCase() === e.target.value) {
                setstartLocation(option.CMCityname);
                setActiveStartLocation(option.CMCityid)
                setActiveEndLocation(null)
                setendLocation("")
                setSourceInputSuggestion(false);
                setDestinationInputSuggestion(true) 
                destinationInputRef.current.focus(); 
              }
            });

        }
    };
    const onChnageEndLocationFunction = (e) => {
        let valid;

        try {
            if (e.target.value !== "") {
                valid = e.target.value.match(/^[A-Za-z ]+$/);
            } else {
                valid = "";
            }
        } catch (err) {
            valid = null;
        }
        if (valid !== null) {
            e.preventDefault();
            // setEndLocationTyping(true)
            if(!activeStartLocation) {
                cogoToast.warn(
                    <CustomToast
                        message="Please select Source city first"
                        type={"warn"}
                    />,
                    {position: "top-center"}
                   
                );
                 setDestinationInputSuggestion(false);
                 setSourceInputSuggestion(true);
                 setstartLocation("")
                 sourceInputRef.current.focus();
                 return
            }
            setendLocation(e.target.value);
            let sourceListId =
                sourcePointListFromStore?.length > 0
                    ? sourcePointListFromStore[0].CMCityid
                    : null;
            if (sourceListId !== null) {
                let variable = {
                    searchTerm: e.target.value?.trim(),
                    cityId:
                        parseInt(!activeStartLocation
                            ? sourceListId
                            : activeStartLocation),
                };
                if(sourceListId !== null  && activeStartLocation !== null) getDestinationList(variable);
                let result =  destinationPointListFromStore.filter(item=>((`${item.CMCityname}`).toLowerCase()).includes((e.target.value).toLowerCase()));
            setDestinationSuggestionList(result);
                destinationSuggestionList.forEach(option => {
                    //|| (destinationInputSuggestion.length===1 && option.CMCityname.trim().toLowerCase().includes((e.target.value).toLowerCase()))
                    if (option.CMCityname.trim().toLowerCase() === e.target.value ) {
                        setendLocation(option.CMCityname);
                        setActiveEndLocation(option.CMCityid)
                      setDestinationInputSuggestion(false)
                    }
                  });
                
            } else {
                cogoToast.warn(
                    <CustomToast
                        type={"warn"}
                        heading={"Warning"}
                        message={"Your Source Point was currently not Serviceable"}
                    />,
                    {position: "top-center"}
                );
            }
        }
    };
    const flipfun = () => {
        setstartLocation(endLocation);
        setendLocation(startLocation);
        setActiveStartLocation(activeEndLocation)
        setActiveEndLocation(activeStartLocation)
    };
    const todayfun = () => {
        setCalendarValue(new Date());
        setcalenderbol(convertDateWithMonth(new Date()));
    };
    const Tomorrowfun = () => {
        const today = new Date();
        const tomorrow = new Date(today);

        tomorrow.setDate(tomorrow.getDate() + 1);

        setCalendarValue(tomorrow);
        setcalenderbol(convertDateWithMonth(tomorrow));
    };

    const DayAfterTomorrowfun = () => {
        const today = new Date();
        const tomorrow = new Date(today);

        tomorrow.setDate(tomorrow.getDate() + 2);

        setCalendarValue(tomorrow);
        setcalenderbol(convertDateWithMonth(tomorrow));
    };

    const searchBusSubmission = () => {
        if (startLocation=="" && endLocation=="" && activeStartLocation === null && activeEndLocation === null  ) {
            cogoToast.warn(
                <CustomToast
                    message="Please select Source & Destination cities"
                    type={"warn"}
                />,
                {position: "top-center"}
            );
             store.dispatch(setAvailableBusList([]));
            return;
        }
        else if (startLocation=="" || activeStartLocation === null  ) {
            cogoToast.warn(
                <CustomToast
                    message="Please select Source City First"
                    type={"warn"}
                />,
                {position: "top-center"}
            );
             store.dispatch(setAvailableBusList([]));
            return;
        }
        else if ( endLocation=="" || activeEndLocation === null  ) {
            cogoToast.warn(
                <CustomToast
                    message="Please select Destination city"
                    type={"warn"}
                />,
                {position: "top-center"}
            );
             store.dispatch(setAvailableBusList([]));
            return;
        }
        const today = new Date();
        const tomorrow = new Date(today);

        tomorrow.setDate(tomorrow.getDate() + 1);

        setCalendarValue(tomorrow);
        //moengage event
        window.Moengage.track_event("bus_search", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: "website",
            fullname: `${userName === null ? "" : userName}`,
            mobile_number: `${userNmber === null ? "" : userNmber}`,
            email: `${userEmail === null ? "" : userEmail}`,
            isloggedin: `${userNmber === null ? "no" : "yes"}`,
            from: `${startLocation}`,
            fromId: `${activeStartLocation}`,
            to: `${endLocation}`,
            toId: `${activeEndLocation}`,
            bus_route: `${startLocation}-${endLocation}`,
            date: `${convertDateWithMonth(tomorrow)}`,
            pax: `${pasangercount}`,
            origin: "Calendar",
        });

        window.gtag('event', 'search_event_home',
            {'test_parameter':'from', 'test_value': `${startLocation}`},
            {'test_parameter':'to', 'test_value': `${endLocation}`}
        )


        let sourceListId =
            sourcePointListFromStore?.length > 0
                ? sourcePointListFromStore[0].CMCityid
                : "";
        let destinationListId =
            destinationPointListFromStore?.length > 0
                ? destinationPointListFromStore[0].CMCityid
                : "";

        if (sourceListId !== null && destinationListId !== null) {
            setSourceAndDestinationToStore({
                sourcePoint: startLocation,
                sourceId:
                    !activeStartLocation ? sourceListId : activeStartLocation,
                destinationPoint: endLocation,
                destinationId:
                    !activeEndLocation ? destinationListId : activeEndLocation,
                passengersCount: pasangercount,
                date:
                    calenderbol !== ""
                        ? calenderbol
                        : convertDateWithMonth(calendarValue),
                rawDate: calendarValue,
            });
            let filteredAmenities = [];
            filterDataFromStore.amenities.currentValue.map((item) => {
                if (item) {
                    filteredAmenities.push(parseInt(item));
                }
            });
            let variable = {
                sourceId:
                    parseInt(!activeStartLocation ? sourceListId : activeStartLocation),
                destinationId:
                    parseInt(!activeEndLocation ? destinationListId : activeEndLocation),
                date: convertDateWithMonth(calendarValue),
                seatType: filterDataFromStore.seatType.currentValue,
                busType: filterDataFromStore.busType.currentValue,
                busTiming: filterDataFromStore.busTiming.currentValue,
                endPrice: filterDataFromStore.priceRange.currentValue.endPrice,
                startPrice: filterDataFromStore.priceRange.currentValue.startPrice,
                droppingPoints: getPointsInInt(
                    filterDataFromStore.droppingPoints.currentValue
                ),
                boardingPoints: getPointsInInt(
                    filterDataFromStore.boardingPoints.currentValue
                ),
                amenities:
                    filterDataFromStore.amenities.currentValue.length > 0
                        ? filteredAmenities
                        : [],
                sortBy: filterDataFromStore.sort.currentValue,
            };

            getAvailableBusFilterList(variable, (response) => {
                let tempArray = [];
                tempArray = tempArray.concat(recentSearchDataFromStore);
                let nonRepeatedArray = recentSearchDataFromStore.filter((item) => {
                    return (
                        item.FromCityId === variable.sourceId.toString() &&
                        item.ToCityId === variable.destinationId.toString() &&
                        item
                    );
                });

                if (nonRepeatedArray.length === 0) {
                    if (recentSearchDataFromStore.length < 5) {
                        tempArray.push(response[0]);
                        store.dispatch(setRecentSearchAction(tempArray));
                    } else {
                        // remove first element of the recentSearchDataFromStore and append the new variable
                        tempArray.shift();
                        tempArray.push(response[0]);
                        store.dispatch(setRecentSearchAction(tempArray));
                    }
                }
            });
            navigate(`/booking?from=${startLocation}&to=${endLocation}`, {replace: false});
        } else {
            cogoToast.warn(
                <CustomToast
					heading={"Warning"}
                    message="Your Source or Destination was not serviceable"
                    type={"warn"}
                />,
                {position: "top-center"}
            );
        }

        let clearedValue = {
            seatType: {
                feildName: "seatType",
                currentValue: [],
                option: getValues("BookingSeatType"),
            },
            busType: {
                feildName: "busType",
                currentValue: [],
                option: getValues("BusTypeEnum"),
            },
            busTiming: {
                feildName: "busTiming",
                currentValue: [],
                option: getValues("BusTimingEnum"),
            },
            priceRange: {
                feildName: "priceRange",
                currentValue: {
                    startPrice: 0.01,
                    endPrice: 5000.0,
                },
            },
            droppingPoints: {
                feildName: "droppingPoints",
                currentValue: [],
                option: store.getState().FilterReducer.droppingPoints,
            },
            boardingPoints: {
                feildName: "boardingPoints",
                currentValue: [],
                option: store.getState().FilterReducer.boardingPoints,
            },
            amenities: {
                feildName: "amenities",
                currentValue: [],
            },
            sort: {
                feildName: "sort",
                currentValue: getValues("BusListingSortEnum")
                    ? getValues("BusListingSortEnum")[1]
                    : "FARE_HIGH_TO_LOW",
                option: getValues("BusListingSortEnum"),
            },
        };
        setFilterObject(clearedValue);
        store.dispatch(filterDataAction(clearedValue));
    };

   
	const addPassengerCount = () => {
        if (pasangercount <= 5) {
            setpasangercount(pasangercount + 1);
        } else {
            cogoToast.warn(
                <CustomToast
                    heading={"Warning"}
                    type={"warn"}
                    message={"You can't add more than 6 passengers"}
                />,
                {position: "top-center"}
            );
        }
    };

    const sourceInputActiveHandler = () => {
        setDestinationInputSuggestion(false);
        setSourceInputSuggestion(true);
        setInputSuggestionList(sourcePointListFromStore)
    };
    const destinationInputActiveHandler = () => {
        if (activeStartLocation && startLocation) {
        setDestinationInputSuggestion(true);
        setSourceInputSuggestion(false);
        } 
    else{ 
        if(startLocation && !activeStartLocation){
            {cogoToast.warn(
                <CustomToast
                    message="Please select Source city first"
                    type={"warn"}
                />,
                {position: "top-center"}
               
                )
             setDestinationInputSuggestion(false);
                setSourceInputSuggestion(true);
                setstartLocation("")
                sourceInputRef.current.focus()}
        }
        else{cogoToast.warn(
        <CustomToast
            message="please select Source city first"
            type={"warn"}
        />,
        {position: "top-center"}
       
        )
     setDestinationInputSuggestion(false);
        setSourceInputSuggestion(true);
        setstartLocation("")
        sourceInputRef.current.focus()}
    }
    };

    const boxActiveDate = () =>{
        return (calendarValue?.getDate() !== new Date().getDate() | calendarValue.getMonth() !== new Date().getMonth()  ) && (calendarValue?.getDate() !== tomorrowDate[1] | calendarValue?.getMonth() !== new Date(new Date().setDate(new Date().getDate() + 1))?.getMonth() ) && (calendarValue?.getDate() !== dayAfterTomorrowDate[1] | calendarValue.getMonth() !== new Date(new Date().setDate(new Date().getDate() + 2)).getMonth()) 
    }

    

  return (
    <div className='innerSubdiv'>
    <div className='search-inputs-container'>
        <div className='search-inputs-container-sub-div'>
            <div 
            className='source-input-bar'
            ref={sourceInputSuggestiondivRef}
            id={"search-source"}
            onClick={sourceInputActiveHandler}
            >
                <img
                src={map_pin}
                className={"location"}
                alt={"source"}
            />

                 <input
                type={"text"}
                ref={sourceInputRef}
                onChange={(e) => onChnageStartLocationFunction(e)}

                onKeyDown={(e) => {
                    if (e.key === "Tab") {

                        setSourceInputSuggestion(false);
                        setDestinationInputSuggestion(true);
                    }
                }}
                pattern="[A-Za-z]"
                value={startLocation?.toLowerCase()}
                placeholder={"Enter Source"}
                className={"start-location capitalize_text"}
            />

            {sourceInputSuggestion && !destinationInputSuggestion ? (
                <div className={"search-suggestion-wrapper"}>
                    <SearchSuggestion
                        searchedText={startLocation}
                        list={inputSuggestionList}
                        setActive={(id, name) => {
                            setActiveStartLocation(id);
                            setstartLocation(name);
                            setendLocation("");
                            setActiveEndLocation(null);
                            setTimeout(() => {
                                setSourceInputSuggestion(false);
                                setDestinationInputSuggestion(true);
                            }, 500);
                        }}
                        active={activeStartLocation}
                    />
                </div>
            ) : null}
            </div>
            <img
            src={flip}
            onClick={() => flipfun()}
            className={"flip"}
            alt={"flip"}
            />
            <div 
            className='destination-input-bar'
            ref={destinationInputSuggestiondivRef}
            id={"search-destination"}
            onClick={destinationInputActiveHandler}
            >
                 <img
                src={map_pin}
                className={"location"}
                alt={"destination"}
            />
                <input
                    type={"text"}
                    onChange={(e) => onChnageEndLocationFunction(e)}
                    pattern="[A-Za-z]"
                    ref={destinationInputRef}
                    value={endLocation?.toLowerCase()}
                    placeholder={"Enter Destination"}
                    className={"end-location capitalize_text"}
                />
                {destinationInputSuggestion && !sourceInputSuggestion ? (
                    <div className={"search-suggestion-wrapper"}>
                        <SearchSuggestion
                            searchedText={endLocation}
                            // list={suggestionList}
                            list={destinationSuggestionList}
                            setActive={(id, name) => {
                                setActiveEndLocation(id);
                                setendLocation(name);
                                setTimeout(() => {
                                    setDestinationInputSuggestion(false);
                                }, 500);
                            }}
                            active={activeEndLocation}
                        />
                    </div>
                ) : null}
            </div>
           
        </div>
        <div className={"date"}>
            <div className={"fast-date-select"}>
                <div
                    className={calendarValue?.getDate() === new Date()?.getDate() && calendarValue?.getMonth() === new Date()?.getMonth()? "selected-date today" : " today"}
                    onClick={() => todayfun()}
                >
                    <span className='day'>
                    {currentDate[0]}
                    </span>
                    
                    <br />
                    <span className='dated'>
                    {currentDate[1]}
                    </span>
                    
                </div>
                <div
                    className={calendarValue?.getDate() === tomorrowDate[1] && calendarValue?.getMonth() === new Date(new Date().setDate(new Date().getDate() + 1))?.getMonth()   ? "selected-date Tomorrow" : "Tomorrow"}
                    onClick={() => Tomorrowfun()}
                >
                     <span className='day'>
                    {tomorrowDate[0]}
                    </span>
                    
                    <br />
                    <span className='dated'>
                    {tomorrowDate[1]}
                    </span>

                </div>
                 <div
                    className={calendarValue?.getDate() === dayAfterTomorrowDate[1] && calendarValue?.getMonth() === new Date(new Date().setDate(new Date().getDate() + 2))?.getMonth() ? "selected-date Tomorrow" : "Tomorrow"}
                    onClick={() => DayAfterTomorrowfun()}
                >
                     <span className='day'>
                    {dayAfterTomorrowDate[0]}
                    </span>
                    
                    <br />
                    <span className='dated'>
                    {dayAfterTomorrowDate[1]}
                    </span>
                </div>
            </div> 
        <div className={boxActiveDate() ? "custome-height cal-date":"custome-height"}>
            <img
                src={boxActiveDate() ? CalenderWhite : calendar}
                className={"Calendar-icon"}
                alt={"Calendar"}
                onClick={() => setViewCalendar(!viewCalendar)}
            />
            {/*<input type={"input"} id={'input'} onClick={() => setCount(0)} className={'calender'}/>*/}

            <input
                type={"input"}
                value={convertDateToDayDateMonth(calendarValue)}
                readOnly={true}
                onClick={() => {
                    setViewCalendar(true);
                }}
                className={ boxActiveDate()? "border-less-input calender-selected":"border-less-input calender" }
            /> 
        </div>

        {viewCalendar && (
            <div className={"calendar-wrapper"}>
                <Calendar
                    // onChange={(value)=>setCalendarValue(value)}
                    // defaultValue={calendarValue}
                    // minDate={new Date()}
                    // inputRef={ref}
                    // maxDate={addDaysToDate(new Date(), 30)}
                    // onClickDay={(e) => {
                    //     // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                    //     //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                    //     setTimeout(() => {
                    //         // deliverySlotChecker(formik, convertDateReverse(e))
                    //         setViewCalendar(false);
                    //     }, 500);
                    // }}
                    // onActiveStartDateChange={handleViewChange}
                    // tileClassName={({ date }) => {
                    //     const dateString = date.toLocaleDateString("en-CA");
                    //     const selectedDateisholiday = calendarValue && (dateString === calendarValue.toLocaleDateString("en-CA"));
                    //     return (flattenedHoliday[dateString] && selectedDateisholiday) ? "selected-holiday" :  flattenedHoliday[dateString] ? "holiday-highlight" : null;
                    //   }}
                    onChange={handleDateChange}
                    defaultValue={calendarValue}
                    minDate={new Date()}
                    inputRef={ref}
                    maxDate={maxDate}
                    onClickDay={handleClickDay}
                    onActiveStartDateChange={handleViewChange}
                    tileClassName={tileClassName}
                />
            {Object.entries(holidaysByMonth)?.length ? ( <ul className="Holidays">
                {Object.keys(holidaysByMonth?.[currentMonth?.toLocaleString("default", { month: "long" })?.toLowerCase()] || [] ).map((value, label) => (<li key={label}><span className="icon-6 rounded-circle" style={{ backgroundColor: "#FFDD00" }}></span><span className="holiday-info">{new Date(value).getDate()} -{" "}{holidaysByMonth[currentMonth.toLocaleString("default", { month: "long" }).toLowerCase()]?.[value]} </span></li>))}</ul>) : null}
            </div>
        )}
        </div>

    </div>
    <button 
        className='homepage-d-search-button'
        onClick={() => searchBusSubmission()}
        >
            <img src={search} className={"Search "} alt={"Search"}/>
            Search Buses
    </button>
    </div>
  )
}

export default InnerSearchbar
import React from 'react'
import './RouteHeaderComponent.scss'
import headerIcon from '../../assets/icons/popular-routes.svg'
import { Link } from 'react-router-dom'
import Markdown from 'react-markdown'
import {useMediaQuery} from "react-responsive";

 function RouteHeaderComponent({parentIndex , faqArray ,css}) {
    const heading = 'Discover NueGo'
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    
    return (
        <div className='route-header-component'>
                <div className="header-title-img">
                    <img className='icon-img' src={headerIcon} alt="NueGo popular routes" />
                    <h2 className='title'>{heading}</h2>
                </div>
            <div className="accordion" id="accordionExample">
                               
            {
  faqArray.map((item,index)=>{
                return<div className="accordion" id={`accordionExample-${parentIndex}`} key={index}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${parentIndex}${index}`}>
                        <button
                            className={isDesktopOrLaptop ?'accordion-button show' : 'accordion-button collapsed'}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${parentIndex}${index}`}
                            aria-expanded= {isDesktopOrLaptop ? "true" : 'false'}
                            aria-controls={`collapse${parentIndex}${index}`}
                        >
                            {item.question}

                        </button>
                    </h2>
                    <div
                        id={`collapse${parentIndex}${index}`}
                        className={'accordion-collapse show'}
                        aria-labelledby={`heading${parentIndex}${index}`}
                        // Corrected data-bs-parent to reference the unique accordion ID
                        data-bs-parent={`#accordionExample-${parentIndex}`}
                    >
                        <div className="accordion-body">
                            <div className="text-center">
                                <div className="row text-justify">
                                <br />
                                <Markdown>{item?.seo}</Markdown>
                                    {item.answer.map((item, index) => (
                                        <Link key={index} className={css} to={item?.routeLink}>
                                        <div  >{item?.route}</div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
                  })
} 
            </div>
        </div>
    )
}

export default RouteHeaderComponent

import React, {useEffect, useState} from 'react'
import './CouponListComponent.scss'
import {useMediaQuery} from "react-responsive";
import {MDBModal, MDBModalBody} from "mdbreact";
import {getCouponByRefNoData} from "../../services/api/DataFetching";
import Markdown from "react-markdown";
//assets
import greyDownArrow from "../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg"
import errorIcon from "../../assets/icons/info-red-icon.svg"

const CouponListComponent = (props) => {
    const {applyingCoupon, couponError, setCouponError, referenceNo} = props;
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    const [showDetail, setShowDetail] = useState(false);
    const [index, setIndex] = useState(null);
    const [couponText, setCouponText] = useState("");
    const [couponList, setCouponList] = useState([]);
    useEffect(() => {
        if (referenceNo) {
            let variable = {
                referenceNo: referenceNo
            }
            getCouponByRefNoData(variable, (data) => {
                setCouponList(data)
            }, () => {
                setCouponList([])
            })
        }

    }, []);

    let componentConstant

    if (isDesktopOrLaptop) {
        componentConstant = {
            couponCode_class: "open-700w-16s-24h",
            couponDetail_class: " open-600w-16s-24h mb-0 opacity-80",
            description_class: "open-400w-14s-22h mb-0 opacity-80",
            details_class: "",
            input: "w-75 ",
            verticalText: "open-700w-16s-24h text-center  ",
            cta: " open-600w-14s-22h "
        }
    } else {
        componentConstant = {
            couponCode_class: "open-700w-18s-28h",
            couponDetail_class: "open-600w-14s-22h mb-0 opacity-80",
            description_class: "open-400w-12s-18h mb-0 opacity-80",
            details_class: "",
            input: "w-100 ",
            verticalText: "open-700w-16s-24h text-center  ",
            cta: " open-600w-12s-18h "
        }
    }

    const submitCoupon = () => {
        let tempCouponList = couponList;
        let res = {}

        if (couponText !== "") {
            tempCouponList.filter(filter => {

                if (filter.discountSchemeName?.toUpperCase() === couponText?.toUpperCase()) {
                    res = filter
                    return filter
                }
            })
            if (Object.entries(res).length === 0 || res.discountId === null) {
                applyingCoupon("", couponText?.toUpperCase())
            } else {
                applyingCoupon(res.discountId, res.discountSchemeName)
            }

        }
    }

    const couponInputHandler = (e) => {
        setCouponText(e.target.value)
        if (e.target.value === "") {
            setCouponError(false)
        }
    }

   const fukrey =  {
    offerCode:"FUKREY",
    discountSchemeName:"FUKREY",
    discountDetails: "Get Flat 10% Instant Discount"
    }

    return (
        <div className={'coupon-list-component p-3'}>
            <p className={'open-400w-16s-24h opacity-60 scroll-to-top-modal-component'}>Enter Coupon Code</p>
            <div
                className={componentConstant.input + 'common-input-wrap position-relative  d-flex  ' + (couponError ? " common-input-wrap-error mb-2 " : " mb-4")}>
                <input type={'text'} className={'w-100'} value={couponText?.toUpperCase()} onChange={(e) => couponInputHandler(e)}/>
                <p className={'open-600w-16s-24h mb-0 cursor-pointer ' + (couponText.length === 0 && 'opacity-60')}
                   onClick={submitCoupon}>APPLY</p>

            </div>
            {couponError && <div className={'mb-4 d-flex'}>
                <img src={errorIcon} alt={'error'} className={'icon-24 mr-3'}/>
                <p className={'mb-0 open-400w-14s-22h'}>This coupon is either expired or invalid</p>
            </div>}
            <div className={'listing'}>
                <p className={'ubuntu-700w-18s-28h'}>Coupon</p>
                {
                    couponList.length > 0 && couponList.map((item, index) => {
                         return <CouponCard key={index} showDetail={showDetail} setShowDetail={setShowDetail}
                                           index={index} setIndex={setIndex}
                                           couponText={couponText} setCouponText={setCouponText}
                                           componentConstant={componentConstant}
                                           applyingCoupon={applyingCoupon}
                                           item={item} isDesktopOrLaptop={isDesktopOrLaptop}
                                           />
                    })
                }
            </div>

            {/*	modals */}
            {/* <MDBModal isOpen={showDetail} fade={true} position={'bottom'}>
                <MDBModalBody>
                    <div className={'details'}>
                        {
                            couponList[index] ? couponList[index].details.map((detail, index) => {
                                    return <div className={'d-flex'}>
                                        <div className={'dot-seperator'}/>
                                        <p className={componentConstant.details_class}>{detail.details}</p>
                                    </div>
                                }) :
                                <p className={'ubuntu-500w-18s-28h'}>No other coupons available</p>
                        }
                    </div>
                </MDBModalBody>
            </MDBModal> */}
        </div>
    )
}

export const CouponCard = (props) => {
    const {
        item, isDesktopOrLaptop, showDetail, index, setShowDetail, componentConstant
        , couponText, setCouponText, setIndex, applyingCoupon 
    } = props;

    const [offerViewDetails, setOfferViewDetails] = useState([])
    const [activeIndex, setActiveIndex] = useState(null); 

    const detailHandler = (index) => {
        // If clicking the same item, close it
        if (activeIndex === index) {
            setActiveIndex(null);
            setOfferViewDetails([]); 
            return;
        }
        setActiveIndex(index);
        setShowDetail(true);
    
        fetch(process.env.REACT_APP_CMS_PROD_API + `api/offers?populate=deep&sort=rank:asc`)
            .then((res) => res.json())
            .then((res) => {
                const validOffers = res?.error?.status === 500 ? [] : res.data.filter(item => 
                    new Date(item.attributes.expiry).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
                );
                let tnc = validOffers.filter(el => el?.attributes?.code === item.discountSchemeName);
                setOfferViewDetails(tnc);
            })
            .catch((err) => {
                console.error("Error fetching offers details:", err);
            });
    
        if (item.offerCode) {
            setCouponText(item.offerCode);
        }
    };

    const applyCouponFromCard = () => {
        
        const scrollToTopPage = document.querySelector(
            `.scroll-to-top-modal-component`
        );
        scrollToTopPage?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
        });
        if (item.discountSchemeName) {
            setCouponText(item.discountSchemeName)
        }
        applyingCoupon(item.discountId, item.discountSchemeName)
    }

    return (
        <div className={'coupon-card d-flex mb-3'}>
            <div className={'vertical-text-wrapper'}>
                <p className={componentConstant.verticalText + ' mb-0 white-color '}>{item.discountSchemeName}</p>
            </div>
            <div className={'p-3 w-100'}>
                <div className={'d-flex justify-content-between '}>
                    <div className={'d-flex'}>
                        {/* <img src={item.imagePath} alt={'alt'} className={'icon-24 mr-2'}/> */}
                        <p className={componentConstant.couponCode_class + " mr-3"}>{item.offerCode}</p>
                        {isDesktopOrLaptop &&
                            <p className={componentConstant.couponDetail_class}>{item.discountDetails}</p>}
                    </div>
                    <p className={'open-600w-16s-24h teal-2-00A095-color cursor-pointer'}
                       onClick={() => applyCouponFromCard()}>APPLY</p>
                </div>
                {(!isDesktopOrLaptop) && <p className={componentConstant.couponDetail_class}>{item.discountDetails}</p>}
                <hr className={'horizontal-divider'}/>
                <p className={componentConstant.description_class}></p>
                <div className={'d-flex'} onClick={() => detailHandler(index)}>
                    <p className={'teal-2-00A095-color cursor-pointer ' + componentConstant.cta}>View Details</p>
                    {isDesktopOrLaptop && <img src={greyDownArrow} className={'icon-24 ml-1'} alt={'alt'}/>}
                </div>
                {
                    activeIndex === index &&  <div className={'details'}>
                        {
                
                             offerViewDetails.map((el, index) => {return <div className={'d-flex flex-column'} key={index}>
                                    <div/>
                                <Markdown>{el.attributes.tnc}</Markdown>
                                    
                                </div>
                            })
                        }
                    </div>
                }

            </div>

        </div>
    )
}

export default CouponListComponent

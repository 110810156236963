import React ,{useState}from 'react';
import './FeatureCard.scss';
import { Link , useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import cogoToast from "cogo-toast";
import { CustomToast } from "../../../../../components/CustomToast/CustomToast";
import { NewsletterUser } from '../../../../../services/api/DataUpdating';

const FeatureCard = ({featuredBlogs}) => {
  const [email , setEmail ] = useState('')
  const navigate = useNavigate();
  console.log('featuredBlogs',featuredBlogs)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const activateNewsLetter = ()=>{
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(emailRegex.test(email)){  
            let variables = {
              email: email,
            };
            NewsletterUser(variables, (response) => {
              cogoToast.success(
                <CustomToast
                  message={response.message}
                  type={"success"}
                  heading={"Success"}
                />,
                { position: "top-center" }
              );
              setEmail("");
            });
      }
      else{
        cogoToast.warn(
          <CustomToast
              type={"warn"}
              message={
                  'Please enter a valid email'
              }
              heading={"Warning"}
          />,
          { position: "top-center" }
      );
      }
  }

  const onChangeHandler= (e)=>{
      e.preventDefault();
      setEmail(e.target.value)
  }
  
{ return featuredBlogs.length ? <div className='feature-card'>
  <div className="grid-container">
   <div className='first-column'>
   {/* <Link to='/blog/solo-travel-destinations-for-women' style={{ textDecoration: 'none', display: 'block' }}> */}
     <div
     onClick={()=>navigate(featuredBlogs[0]?.attributes?.route)}
     style={{backgroundImage:`url(${ isDesktopOrLaptop ? featuredBlogs[0]?.attributes.img : featuredBlogs[0]?.attributes.mwebImg})`}}
      className={`first-column-first-card`}>
       {/* <img src={item.img} alt={item.imgAlt} /> */}
       <div className='mweb'>
         <p>{featuredBlogs[0]?.attributes?.tags[0]}</p>
         <h3>{featuredBlogs[0]?.attributes?.title}</h3>
         <a>{featuredBlogs[0]?.attributes?.readingTime}</a>
       </div>
      
     </div>
   {/* </Link> */}

     <div
      className={`card-newsletter`}>
       <h2>Stay updated! Sign up to our newsletter</h2>
       <p>Receive travel tips, destination guides, and exclusive offers directly to your inbox.</p>
      <div>
      <input type="email" value={email} onChange={(e)=>onChangeHandler(e) }required placeholder="Enter Email Address" />
      <button onClick={activateNewsLetter}>Sign Up</button>
      </div>
     </div>
   </div>
       <div
       onClick={()=>navigate(featuredBlogs[1]?.attributes?.route)}
       style={{backgroundImage:`url(${isDesktopOrLaptop? featuredBlogs[1].attributes?.img : featuredBlogs[1].attributes?.mwebImg})`}}
       className={`grid-item item2`}>
         {/* <img src={item.img} alt={item.imgAlt} /> */}
         <div className='mweb'>
           <p>{featuredBlogs[1]?.attributes?.tags[0]}</p>
           <h3>{featuredBlogs[1]?.attributes?.title}</h3>
           <a>{featuredBlogs[1]?.attributes?.readingTime}</a>
         </div>       
       </div>
     
     <Link to={featuredBlogs[2]?.attributes?.route}>
     <div
     style={{backgroundImage:`url(${featuredBlogs[2]?.attributes?.mwebImg})`}}
      className={`grid-item item3`}>
       {/* <img src={item.img} alt={item.imgAlt} /> */}
       <div className='mweb'>
         <p>{featuredBlogs[2]?.attributes?.tags[0]}</p>
         <h3>{featuredBlogs[2]?.attributes?.title}</h3>
         <a>{featuredBlogs[2]?.attributes?.readingTime}</a>
       </div>
      
     </div>
     </Link>
     
     <Link to={featuredBlogs[3]?.attributes?.route}>
     <div
     style={{backgroundImage:`url(${featuredBlogs[3]?.attributes?.mwebImg})`}}
      className={`grid-item item5`}>
       {/* <img src={item.img} alt={item.imgAlt} /> */}
       <div className='mweb'>
         <p>{featuredBlogs[3]?.attributes?.tags[0]}</p>
         <h3>{featuredBlogs[3]?.attributes?.title}</h3>
         <a>{featuredBlogs[3]?.attributes?.readingTime}</a>
       </div>
      
     </div>
     </Link>
  
 </div>
</div>
: null}
}

export default FeatureCard;

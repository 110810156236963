import React , { useState , useEffect } from 'react'
import SearchBarMweb from '../../../../components/SearchBarMweb/SearchBarMweb'
import { data } from './data'
import { Link } from 'react-router-dom'
import {useMediaQuery} from "react-responsive";
import axios from 'axios';

function ExtraBlogPart({dataContentTitle}) {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const [latestBlogs , setLatestBlogs] = useState([])

    const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_CMS_PROD_API}api/homepage-blogs?populate[img][fields][0]=url&populate[mwebImg][fields][1]=url&fields[0]=title&fields[3]=route&sort=rank:desc&pagination[page]=1&pagination[pageSize]=10`);
          if(response.data.data.length > 0 ){
            setLatestBlogs(response.data.data)
          }
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []);
      
  return (
    <div className="extra-blog">
    <div className="outer-main-card">
            <div className="inner-card">
                <div className="more-on-and-view-all">
                    <h2 className="extra-title">Latest Blogs</h2>
                    {/* <p className="view-all" >View All &gt;</p> */}
                </div>
                <div className="extra-blog-outer">
                    {
                    latestBlogs.filter(el=>el?.attributes?.title !== dataContentTitle).sort(() => 0.5 - Math.random()).slice(0, 4).map((el,ind)=>{

                    return<div className="extra-blog-div">
                    <img src={el?.attributes?.mwebImg} alt="" />
                    <div className="mweb">
                        <h3>{el?.attributes?.title}</h3>
                    <Link to={el?.attributes?.route}>Read More &gt;</Link>
                    </div>
                        </div>
                    })

                    }
                
                </div>
            </div>
        
    </div>
    <SearchBarMweb/>
    </div>  
  )
}

export default ExtraBlogPart
import React ,{useState , useRef} from 'react'
import "./BlogCategory.scss"
import { data }from "../data"
import { Link } from 'react-router-dom'
import {useMediaQuery} from "react-responsive";

function CategoryBlogs({alldata}) {

    const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
    });
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [showAll, setShowAll] = useState(false);
    const containerRef = useRef('category');
    
    const filteredData = selectedCategory === 'All'
    ? alldata?.data===undefined ?  [] : alldata?.data
    : alldata?.data.filter(item => item?.attributes?.tags.includes(selectedCategory));

    

    const itemsToDisplay = (showAll && filteredData?.length)  ? filteredData : (!showAll && filteredData?.length>3) ? filteredData?.slice(0, 3) : (!showAll && filteredData?.length) ? filteredData :  [];
    
    const setView = ()=>{
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
      setShowAll(!showAll)
    }
  return Object.entries(alldata).length>0 && (
    <div className='blog-page-category-section' ref={containerRef} >
              <div className='head-subhead'>
            <div className='head'>
                <img className='icon' src="https://cdn.nuego.co.in/greencell/assets/images/Discount.png" alt="" />
                <h2 className='heading'>Blogs: Travel Insights and Inspiration</h2>
            </div>
            <h3 className='subhead'>Join us as we uncover hidden gems and explore breathtaking destinations worldwide.</h3>
      </div>
    {/* Category Tabs */}
    <div className="category-tabs">
      {['All', 'Adventure', 'Religious', 'Heritage', 'Hidden Gems', 'Weekend Trips' ,'Food Havens'].map(item => (
        <button 
          key={item} 
          onClick={() => setSelectedCategory(item)}
          className={selectedCategory === item ? 'active' : ''}
        >
          {item}
        </button>
      ))}
    </div>

    {/* Render the filtered blog posts */}
    <div className="grid-container">
      {itemsToDisplay.length && itemsToDisplay.map((item, index) => (
        <div  key={index} className={`grid-item  ${index === 0 ? 'item1' : index === 1 ? 'item2' : 'item3'}`}>
          <Link to={item?.attributes?.route}><img src={isDesktopOrLaptop? item?.attributes?.img : item?.attributes?.mwebImg} alt={item?.attributes?.imgAlt} /></Link>
          <div className='mweb'>
          <Link to={item.route}><h3>{item?.attributes?.title}</h3></Link>
            <p>{item?.attributes?.desc}</p>
            <Link to={item?.attributes?.route}>Read More &gt;</Link>
          </div>    
        </div>
      ))}
    </div>
    {filteredData?.length > 3 && <button className='view-all-cta' onClick={setView}>{showAll?'View Less':'View All'}</button>}
  </div>
  )
}

export default CategoryBlogs
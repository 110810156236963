import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../environment";

const mutation = graphql`
  mutation TryAnotherBookingMutation($bookingId: String, $reason: String) {
    tryAnotherBooking(bookingId: $bookingId, reason: $reason) {
      message
    }
  }
`;

export default (data, callback, errCallback) => {
  const variables = {
    bookingId: data.bookingId,
    reason: data.reason,
  };
  commitMutation(environment, {
    mutation,
    variables,

    onCompleted: (response, err) => {
      if (response.tryAnotherBooking !== null) {
        callback(response);
      } else {
        errCallback(err[0].message);
      }
    },
    onError: (err) => {
      errCallback(err);
    },
  });
};
